<template>
  <!-- 商户产品 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
      <template slot="product" slot-scope="{ row = {} }">
        <p
          class="order"
          v-for="(item, index) of row.orderDetailItems"
          :key="index"
        >
          <span class="marR10">产品名称: {{ item.productName }} </span>
          <span class="marR10">
            计费模式:
            {{ item.productCalcMode == 1 ? '包年包月' : '数量或次数' }}</span
          >
          <span class="marR10"
            >数量: {{ item.productQty
            }}{{ item.productCalcMode == 1 ? '个' : '套' }}</span
          >
          <span class="marR10"> 金额: {{ item.productMoney }}元</span>
        </p>
        <p class="order">合计金额: {{ row.orderMoney }}元</p>
      </template>
    </TablePage>

    <el-dialog
      title="申请退款"
      v-dialog-drag
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="diaClose"
    >
      <div>
        <el-form :model="form" ref="formRef" :rules="rules" label-width="100px">
          <el-form-item label="订单号">
            <el-input v-model="form.ordNo" disabled></el-input>
          </el-form-item>
          <el-form-item label="退款金额" prop="amt">
            <el-input
              style="width: 200px"
              v-model="form.amt"
              placeholder="请输入退款金额"
              :max="maxAmt"
            ></el-input>
            <span class="marL10">元</span>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="diaClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import { getOrderList, orderRefundOrder } from '@/api/shop/mobileQueryApp'
import TablePage from '@/components/tablePage/index.vue'
import { setAudit } from '@/api/tenant/product/order'

export default {
  name: 'TenantList',
  components: { TablePage, cardTitleCom, seniorSearch, TablePagination },
  dicts: ['pay_status'],
  data () {
    return {
      options: {
        listNo: true,
        loading: true,
        check: [],
        mutiSelect: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15
        },
        defaultBody: {},
        getListApi: getOrderList,
        title: '商户产品',
        rowKey: 'orderId',
        search: [
          {
            clearable: true,
            label: '日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'input',
            tip: '请输入商户号、商户名称',
            model: '',
            filter: 'query'
          },
          {
            label: '订单号',
            type: 'input',
            tip: '请输入订单号',
            seniorSearch: true,
            model: '',
            filter: 'orderNo'
          },
          {
            label: '商户编号',
            type: 'input',
            tip: '请输入商户编号',
            seniorSearch: true,
            model: '',
            filter: 'tenantNo'
          },
          {
            label: '商户名称',
            type: 'input',
            tip: '请输入商户名称',
            seniorSearch: true,
            model: '',
            filter: 'tenantName'
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'add',
            type: 'primary',
            label: '新增',
            icon: 'el-icon-plus'
          },
          {
            click: 'audit',
            type: 'primary',
            label: '审核',
            num: 1
          },
          {
            click: 'tk',
            type: 'primary',
            label: '申请退款',
            num: 1
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete'
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh-right',
            type: ''
          }
        ],
        columns: [
          {
            prop: 'orderNo',
            type: 'link',
            click: 'link',
            label: '订单号',
            minWidth: '260'
          },
          {
            prop: 'orderStatus',
            type: 'dicts',
            name: 'pay_status',
            status: 'orderStatus',
            label: '订单状态',
            minWidth: '120'
          },
          { prop: 'createTime', label: '订单时间', minWidth: '155' },
          { prop: 'tenantNo', label: '商户编号', width: '120' },
          { prop: 'tenantName', label: '商户名称', width: '150' },
          { prop: 'linkMan', label: '联系人', minWidth: '130' },
          { prop: 'linkManTel', label: '联系电话', minWidth: '120' },
          {
            prop: 'product',
            type: 'slot2',
            label: '产品',
            minWidth: '400'
          },
          { prop: 'orderOldMoney', label: '订单原价金额', minWidth: '120' },
          { prop: 'orderMoney', label: '订单金额', minWidth: '120' },
          { prop: 'orderPayMoney', label: '实付金额', minWidth: '120' },
          { prop: 'orderDiscountMoney', label: '优惠金额', minWidth: '120' },
          { prop: 'orderPayModeName', label: '支付方式', minWidth: '120' },
          { prop: 'orderPayTime', label: '支付时间', minWidth: '155' },
          { prop: 'refundOrderMoney', label: '退款金额', minWidth: '120' },
          { prop: 'refundApplyTime', label: '申请退款时间', minWidth: '155' },
          { prop: 'refundFinishTime', label: '退款完成时间', minWidth: '155' },
          { prop: 'remark', label: '备注', minWidth: '155' },
          { prop: 'createBy', label: '创建用户', minWidth: '120' },
          { prop: 'createTime', label: '创建时间', minWidth: '155' }
        ],
        list: []
      },

      product: [],

      maxAmt: 0, // 最大退款金额
      dialogVisible: false, // 退款弹框
      form: {
        ordNo: '',
        amt: ''
      },
      rules: {
        ordNo: [],
        amt: [
          {
            required: true,
            message: '请输入退款金额，不能大于实付金额',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.options.defaultBody.tenantId = vm.$route?.query?.tenantId
      console.log(' vm.options', vm.options)
    })
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'getListed':
          if (this.options.list?.length) {
            this.product = this.options.list[0].orderDetailItems || []
            console.log(this.product)
          }
          break
        case 'link':
          await this.$router.push(
            '/tenant/product/order/orderDetail?orderId=' + row.orderId
          )
          break
        case 'add':
          await this.$router.push('/tenant/product/order/orderDetail')
          break
        case 'del':
          await this.$router.push(
            `/tenant/product/saas/productDetail?type=update&productId=${this.options.check[0].productId}`
          )
          break
        case 'tk':
          this.form.ordNo = this.options.check[0].orderNo
          this.form.amt = this.options.check[0].orderPayMoney
          this.maxAmt = this.options.check[0].orderPayMoney
          this.dialogVisible = true
          break
        case 'audit':
          this.handleBillStatus()
          break
      }
    },

    handleBillStatus () {
      const orderIds = this.options.check
        .map(i => {
          if (
            i.orderStatus == '0' ||
            i.orderStatus == '1' ||
            i.orderStatus == '2'
          ) {
            return i.orderId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      if (orderIds.length == 0)
        return this.$message.error('所选项没有未审核的订单')
      this.$confirm('您确认要审核选中的订单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await setAudit({
            orderIds,
            orderStatus: 3
          })
          await this.$refs.tablePage.getList()
          this.$modal.msgSuccess(`审核成功`)
        })
        .catch(() => {})
    },

    diaClose () {
      this.dialogVisible = false
    },

    submit () {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          orderRefundOrder(this.form).then(res => {
            this.dialogVisible = false
            this.$message.success('退款成功')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.order {
  font: {
    size: 12px;
  }
  text-align: left;
}
</style>

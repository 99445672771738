var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "handlePackageDetail" },
    [
      _c("top-operating-button", {
        attrs: { showAudit: false, isAuditBillBtn: false },
        on: {
          getQuit: _vm.getQuit,
          submitForm: function ($event) {
            return _vm.submitForm(false)
          },
          addBill: function ($event) {
            return _vm.submitForm(true)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "150px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "cardTitleCom",
                { staticClass: "basicInfo", attrs: { cardTitle: "基本信息" } },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c("div", { staticClass: "cardContent" }, [
                      _c(
                        "div",
                        { staticClass: "x-w" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品编号", prop: "productNo" } },
                            [
                              _c("el-input", {
                                staticClass: "w200",
                                attrs: {
                                  size: "mini",
                                  oninput:
                                    "value=value.replace(/[^0-9a-zA-z]/g,'')",
                                  placeholder: "请输入产品编号",
                                },
                                model: {
                                  value: _vm.form.productNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "productNo", $$v)
                                  },
                                  expression: "form.productNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "产品名称", prop: "productName" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "productName w200",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入产品名称",
                                },
                                model: {
                                  value: _vm.form.productName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "productName", $$v)
                                  },
                                  expression: "form.productName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "单位" } },
                            [
                              _c("el-input", {
                                staticClass: "w200",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入产品单位",
                                },
                                model: {
                                  value: _vm.form.productUnitName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "productUnitName", $$v)
                                  },
                                  expression: "form.productUnitName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "产品类型", prop: "productType" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w200",
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择产品类型",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.productType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "productType", $$v)
                                    },
                                    expression: "form.productType",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.product_type.filter(function (
                                    item
                                  ) {
                                    return item.value != 1 && item.value != 2
                                  }),
                                  function (dict) {
                                    return _c("el-option", {
                                      key: dict.value,
                                      attrs: {
                                        label: dict.label,
                                        value: dict.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "w25",
                              attrs: { label: "上线状态", prop: "status" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w200",
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择产品上线状态",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "status", $$v)
                                    },
                                    expression: "form.status",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.sys_normal_disable,
                                  function (dict) {
                                    return _c("el-option", {
                                      key: dict.value,
                                      attrs: {
                                        label: dict.label,
                                        value: dict.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { staticClass: "w25", attrs: { label: "排序" } },
                            [
                              _c("el-input-number", {
                                staticClass: "w200",
                                attrs: {
                                  size: "mini",
                                  "controls-position": "right",
                                  min: 1,
                                },
                                model: {
                                  value: _vm.form.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sort", $$v)
                                  },
                                  expression: "form.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "introduction-logo x-start" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品图片", prop: "logoUrl" } },
                            [
                              _c("SingleImageUpload", {
                                attrs: { fileList: _vm.fileList },
                                on: {
                                  getImageUrl: _vm.handleImageUrl,
                                  delImage: _vm.handleImageUrl,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产品介绍",
                                prop: "introduction",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "w200",
                                attrs: {
                                  type: "textarea",
                                  rows: 4,
                                  placeholder: "请输入产品介绍",
                                },
                                model: {
                                  value: _vm.form.introduction,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "introduction", $$v)
                                  },
                                  expression: "form.introduction",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "introduction" }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                { staticClass: "basicInfo", attrs: { cardTitle: "计费模式" } },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "cardContent" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "计费模式",
                              "label-width": "150px",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: { input: _vm.changeProductCalcType },
                                model: {
                                  value: _vm.form.productCalcType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "productCalcType", $$v)
                                  },
                                  expression: "form.productCalcType",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.product_calc_type,
                                function (dict) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: dict.value,
                                      attrs: { label: dict.value },
                                    },
                                    [_vm._v(_vm._s(dict.label) + " ")]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "payModelTypeArea" },
                          [
                            _vm._l(
                              _vm.form.payModelItems,
                              function (item, index_i) {
                                return _c(
                                  "div",
                                  { key: index_i, staticClass: "y-start" },
                                  [
                                    _vm.form.productCalcType == 1
                                      ? _c(
                                          "div",
                                          { staticClass: "x-f" },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "付费时长",
                                                  "label-width": "150px",
                                                  prop:
                                                    "payModelItems[" +
                                                    index_i +
                                                    "].payModelType",
                                                  rules:
                                                    _vm.rules[
                                                      "payModelItems.payModelType"
                                                    ],
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder:
                                                        "请选择付费模式",
                                                      size: "mini",
                                                    },
                                                    model: {
                                                      value: item.payModelType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "payModelType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.payModelType",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.dict.type
                                                      .product_years_package_mode,
                                                    function (dict) {
                                                      return _c("el-option", {
                                                        key: dict.value,
                                                        attrs: {
                                                          label: dict.label,
                                                          value: dict.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "天数",
                                                  "label-width": "80px",
                                                  prop:
                                                    "payModelItems[" +
                                                    index_i +
                                                    "].payModelTypeValue",
                                                  rules:
                                                    _vm.rules[
                                                      "payModelItems.payModelTypeValue"
                                                    ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder: "请输入天数",
                                                    oninput:
                                                      "value=value.replace(/^0|[^0-9]/g,'')",
                                                  },
                                                  model: {
                                                    value:
                                                      item.payModelTypeValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "payModelTypeValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.payModelTypeValue",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  "label-width": "10px",
                                                },
                                              },
                                              [
                                                index_i == 0
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          size: "mini",
                                                          icon: "el-icon-plus",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleRow(
                                                              "push",
                                                              index_i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("添加 ")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  "label-width": "10px",
                                                },
                                              },
                                              [
                                                index_i > 0
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "danger",
                                                          icon: "el-icon-delete",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleRow(
                                                              "del",
                                                              index_i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除 ")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "x-f" },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "产品原价" } },
                                          [
                                            _c("el-input", {
                                              staticClass: "w200",
                                              attrs: {
                                                size: "mini",
                                                oninput:
                                                  "value=value.toString().match(/^\\d+(?:\\.\\d{0,2})?/)",
                                                placeholder: "请输入产品原价",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.calculateProcuct(
                                                    item,
                                                    "productOldPrice",
                                                    "productDiscount",
                                                    "productPrice"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.productOldPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "productOldPrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.productOldPrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "产品折扣(%)" } },
                                          [
                                            _c("el-input", {
                                              staticClass: "w200",
                                              attrs: {
                                                size: "mini",
                                                oninput:
                                                  "value=value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,2})?.*$/,'$1')",
                                                placeholder: "请输入产品折扣",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.calculateProcuct(
                                                    item,
                                                    "productOldPrice",
                                                    "productDiscount",
                                                    "productPrice"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.productDiscount,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "productDiscount",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.productDiscount",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "产品价格" } },
                                          [
                                            _c("el-input", {
                                              staticClass: "w200",
                                              attrs: {
                                                disabled: "",
                                                size: "mini",
                                                oninput:
                                                  "value=value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,2})?.*$/,'$1')",
                                                placeholder: "请输入产品价格",
                                              },
                                              model: {
                                                value: item.productPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "productPrice",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.productPrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.form.productCalcType == 2
                                          ? _c(
                                              "div",
                                              { staticClass: "x-f" },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      "label-width": "10px",
                                                    },
                                                  },
                                                  [
                                                    index_i == 0
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                              size: "mini",
                                                              icon: "el-icon-plus",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleRow(
                                                                  "push",
                                                                  index_i
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("添加 ")]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      "label-width": "10px",
                                                    },
                                                  },
                                                  [
                                                    index_i > 0
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "danger",
                                                              icon: "el-icon-delete",
                                                              size: "mini",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleRow(
                                                                  "del",
                                                                  index_i
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("删除 ")]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "x-f" },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { "label-width": "100px" } },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                on: { change: _vm.onIsUsers },
                                                model: {
                                                  value: item.isManageUsers,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "isManageUsers",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.isManageUsers",
                                                },
                                              },
                                              [_vm._v("是否管控用户数")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    item.isManageUsers
                                      ? _c(
                                          "div",
                                          { staticClass: "x-f" },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "initializeUsers" + index_i,
                                                refInFor: true,
                                                attrs: {
                                                  label: "初始用户数",
                                                  "label-width": "140px",
                                                  prop:
                                                    "payModelItems[" +
                                                    index_i +
                                                    "].initializeUsers",
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message:
                                                        "请输入初始用户数",
                                                      trigger: "blur",
                                                    },
                                                  ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w200",
                                                  attrs: {
                                                    size: "mini",
                                                    maxlength: "10",
                                                    placeholder:
                                                      "请输入初始用户数",
                                                  },
                                                  model: {
                                                    value: item.initializeUsers,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "initializeUsers",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "item.initializeUsers",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "initializeUsersPrice" +
                                                  index_i,
                                                refInFor: true,
                                                attrs: {
                                                  label: "初始用户单价",
                                                  prop:
                                                    "payModelItems[" +
                                                    index_i +
                                                    "].initializeUsersPrice",
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message:
                                                        "请输入初始用户单价",
                                                      trigger: "blur",
                                                    },
                                                  ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w200",
                                                  attrs: {
                                                    size: "mini",
                                                    type: "number",
                                                    maxlength: "10",
                                                    placeholder:
                                                      "请输入初始用户单价",
                                                  },
                                                  model: {
                                                    value:
                                                      item.initializeUsersPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "initializeUsersPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.initializeUsersPrice",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.isManageUsers
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "x-f1 y-start",
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  label: "用户数价格",
                                                  "label-width": "140px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-table",
                                                  {
                                                    ref: "Table",
                                                    refInFor: true,
                                                    staticClass: "table",
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      data: item.saasProductUsersPrices,
                                                      "row-key": "columnId",
                                                      "max-height": "300",
                                                      border: "",
                                                    },
                                                    on: {
                                                      "cell-mouse-enter":
                                                        function ($event) {
                                                          return _vm.cellMouseEnter(
                                                            $event,
                                                            "saasProductUsersPrices",
                                                            index_i
                                                          )
                                                        },
                                                      "cell-mouse-leave":
                                                        function ($event) {
                                                          return _vm.cellMouseLeave(
                                                            "saasProductUsersPrices",
                                                            index_i
                                                          )
                                                        },
                                                    },
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        align: "center",
                                                        width: "80",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function (
                                                              scope
                                                            ) {
                                                              return [
                                                                scope.row
                                                                  .hoverRow
                                                                  ? _c("i", {
                                                                      staticClass:
                                                                        "el-icon-circle-plus operatePush",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.tableHandleRow(
                                                                              item.saasProductUsersPrices,
                                                                              "push",
                                                                              scope.$index
                                                                            )
                                                                          },
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                                scope.row
                                                                  .hoverRow
                                                                  ? _c("i", {
                                                                      staticClass:
                                                                        "el-icon-remove operateDel",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.tableHandleRow(
                                                                              item.saasProductUsersPrices,
                                                                              "del",
                                                                              scope.$index
                                                                            )
                                                                          },
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                                !scope.row
                                                                  .hoverRow
                                                                  ? _c("div", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            scope.$index +
                                                                              1
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                    _vm._l(
                                                      _vm.userNumTableField,
                                                      function (i, index_j) {
                                                        return _c(
                                                          "el-table-column",
                                                          {
                                                            key: index_j,
                                                            attrs: {
                                                              label: i.label,
                                                              align: "center",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var row =
                                                                      ref.row
                                                                    var $index =
                                                                      ref.$index
                                                                    return [
                                                                      i.prop !==
                                                                      "usersPrice"
                                                                        ? _c(
                                                                            "el-form-item",
                                                                            {
                                                                              ref:
                                                                                "user" +
                                                                                $index,
                                                                              refInFor: true,
                                                                              attrs:
                                                                                {
                                                                                  "label-width":
                                                                                    "0",
                                                                                  prop:
                                                                                    "payModelItems[" +
                                                                                    index_i +
                                                                                    "].saasProductUsersPrices[" +
                                                                                    $index +
                                                                                    "][" +
                                                                                    i.prop +
                                                                                    "]",
                                                                                  rules:
                                                                                    item.isManageUsers
                                                                                      ? [
                                                                                          {
                                                                                            required: true,
                                                                                            message:
                                                                                              "请输入",
                                                                                            trigger:
                                                                                              "blur",
                                                                                          },
                                                                                        ]
                                                                                      : [],
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-input",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      oninput:
                                                                                        "value=value.toString().match(/^\\d+(?:\\.\\d{0,2})?/)",
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.discountPrices(
                                                                                          row,
                                                                                          "usersOldPrice",
                                                                                          "usersDiscount",
                                                                                          "usersPrice"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        row[
                                                                                          i
                                                                                            .prop
                                                                                        ],
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            row,
                                                                                            i.prop,
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "row[i.prop]",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  row.usersPrice
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "x-f" },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { "label-width": "100px" } },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                on: { change: _vm.onIsShops },
                                                model: {
                                                  value: item.isManageShops,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "isManageShops",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.isManageShops",
                                                },
                                              },
                                              [_vm._v("是否管控门店数")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    item.isManageShops
                                      ? _c(
                                          "div",
                                          { staticClass: "x-f" },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "initializeShops" + index_i,
                                                refInFor: true,
                                                attrs: {
                                                  label: "初始门店数",
                                                  "label-width": "140px",
                                                  prop:
                                                    "payModelItems[" +
                                                    index_i +
                                                    "].initializeShops",
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message:
                                                        "请输入初始门店数",
                                                      trigger: "blur",
                                                    },
                                                  ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w200",
                                                  attrs: {
                                                    size: "mini",
                                                    type: "number",
                                                    maxlength: "10",
                                                    placeholder:
                                                      "请输入初始门店数",
                                                  },
                                                  model: {
                                                    value: item.initializeShops,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "initializeShops",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.initializeShops",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "initializeShopsPrice" +
                                                  index_i,
                                                refInFor: true,
                                                attrs: {
                                                  label: "初始门店单价",
                                                  prop:
                                                    "payModelItems[" +
                                                    index_i +
                                                    "].initializeShopsPrice",
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message:
                                                        "请输入初始门店单价",
                                                      trigger: "blur",
                                                    },
                                                  ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w200",
                                                  attrs: {
                                                    size: "mini",
                                                    maxlength: "10",
                                                    placeholder:
                                                      "请输入初始门店单价",
                                                  },
                                                  model: {
                                                    value:
                                                      item.initializeShopsPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "initializeShopsPrice",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "item.initializeShopsPrice",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.isManageShops
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "x-f1 y-start",
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  label: "门店数价格",
                                                  "label-width": "140px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-table",
                                                  {
                                                    ref: "Table",
                                                    refInFor: true,
                                                    staticClass: "table",
                                                    attrs: {
                                                      data: item.saasProductShopsPrices,
                                                      "row-key": "columnId",
                                                      "max-height": "300",
                                                      border: "",
                                                    },
                                                    on: {
                                                      "cell-mouse-enter":
                                                        function ($event) {
                                                          return _vm.cellMouseEnter(
                                                            $event,
                                                            "saasProductShopsPrices",
                                                            index_i
                                                          )
                                                        },
                                                      "cell-mouse-leave":
                                                        function ($event) {
                                                          return _vm.cellMouseLeave(
                                                            "saasProductShopsPrices",
                                                            index_i
                                                          )
                                                        },
                                                    },
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        align: "center",
                                                        width: "80",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function (
                                                              scope
                                                            ) {
                                                              return [
                                                                scope.row
                                                                  .hoverRow
                                                                  ? _c("i", {
                                                                      staticClass:
                                                                        "el-icon-circle-plus operatePush",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.tableHandleRow(
                                                                              item.saasProductShopsPrices,
                                                                              "push",
                                                                              scope.$index
                                                                            )
                                                                          },
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                                scope.row
                                                                  .hoverRow
                                                                  ? _c("i", {
                                                                      staticClass:
                                                                        "el-icon-remove operateDel",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.tableHandleRow(
                                                                              item.saasProductShopsPrices,
                                                                              "del",
                                                                              scope.$index
                                                                            )
                                                                          },
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                                !scope.row
                                                                  .hoverRow
                                                                  ? _c("div", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            scope.$index +
                                                                              1
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                    _vm._l(
                                                      _vm.shopNumTableField,
                                                      function (i, index_j) {
                                                        return _c(
                                                          "el-table-column",
                                                          {
                                                            key: index_j,
                                                            attrs: {
                                                              label: i.label,
                                                              align: "center",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var row =
                                                                      ref.row
                                                                    var $index =
                                                                      ref.$index
                                                                    return [
                                                                      i.prop !==
                                                                      "shopsPrice"
                                                                        ? _c(
                                                                            "el-form-item",
                                                                            {
                                                                              ref:
                                                                                "shop" +
                                                                                $index,
                                                                              refInFor: true,
                                                                              attrs:
                                                                                {
                                                                                  "label-width":
                                                                                    "0",
                                                                                  prop:
                                                                                    "payModelItems[" +
                                                                                    index_i +
                                                                                    "].saasProductShopsPrices[" +
                                                                                    $index +
                                                                                    "][" +
                                                                                    i.prop +
                                                                                    "]",
                                                                                  rules:
                                                                                    item.isManageShops
                                                                                      ? [
                                                                                          {
                                                                                            required: true,
                                                                                            message:
                                                                                              "请输入",
                                                                                            trigger:
                                                                                              "blur",
                                                                                          },
                                                                                        ]
                                                                                      : [],
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-input",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      oninput:
                                                                                        "value=value.toString().match(/^\\d+(?:\\.\\d{0,2})?/)",
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.discountPrices(
                                                                                          _vm
                                                                                            .scope
                                                                                            .row,
                                                                                          "shopsOldPrice",
                                                                                          "shopsDiscount",
                                                                                          "shopsPrice"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        row[
                                                                                          i
                                                                                            .prop
                                                                                        ],
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            row,
                                                                                            i.prop,
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "row[i.prop]",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  row.shopsPrice
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "x-f" },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { "label-width": "100px" } },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                model: {
                                                  value: item.isManagePoss,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "isManagePoss",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.isManagePoss",
                                                },
                                              },
                                              [_vm._v("是否管控站点数")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    item.isManagePoss
                                      ? _c(
                                          "div",
                                          { staticClass: "x-f" },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "BaseStationNums" + index_i,
                                                refInFor: true,
                                                attrs: {
                                                  label: "初始站点数",
                                                  "label-width": "140px",
                                                  prop:
                                                    "payModelItems." +
                                                    index_i +
                                                    ".initializePoss",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w200",
                                                  attrs: {
                                                    size: "mini",
                                                    maxlength: "10",
                                                    placeholder:
                                                      "请输入初始站点数",
                                                  },
                                                  model: {
                                                    value: item.initializePoss,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "initializePoss",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "item.initializePoss",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  "BaseStationPrice" + index_i,
                                                refInFor: true,
                                                attrs: {
                                                  label: "初始站点单价",
                                                  "label-width": "140px",
                                                  prop:
                                                    "payModelItems." +
                                                    index_i +
                                                    ".initializePossPrice",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w200",
                                                  attrs: {
                                                    size: "mini",
                                                    type: "number",
                                                    maxlength: "10",
                                                    placeholder:
                                                      "请输入初始站点单价",
                                                  },
                                                  model: {
                                                    value:
                                                      item.initializePossPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "initializePossPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.initializePossPrice",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.isManagePoss
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "x-f1 y-start",
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "marL40",
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  label: "站点数价格",
                                                  "label-width": "100px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-table",
                                                  {
                                                    ref: "Table",
                                                    refInFor: true,
                                                    staticClass: "table",
                                                    attrs: {
                                                      data: item.saasProductPossPrices,
                                                      "row-key": "columnId",
                                                      "max-height": "300",
                                                      border: "",
                                                    },
                                                    on: {
                                                      "cell-mouse-enter":
                                                        function ($event) {
                                                          return _vm.cellMouseEnter(
                                                            $event,
                                                            "saasProductPossPrices",
                                                            index_i
                                                          )
                                                        },
                                                      "cell-mouse-leave":
                                                        function ($event) {
                                                          return _vm.cellMouseLeave(
                                                            "saasProductPossPrices",
                                                            index_i
                                                          )
                                                        },
                                                    },
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        align: "center",
                                                        width: "80",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function (
                                                              scope
                                                            ) {
                                                              return [
                                                                scope.row
                                                                  .hoverRow
                                                                  ? _c("i", {
                                                                      staticClass:
                                                                        "el-icon-circle-plus operatePush",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.tableHandleRow(
                                                                              item.saasProductPossPrices,
                                                                              "push",
                                                                              scope.$index
                                                                            )
                                                                          },
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                                scope.row
                                                                  .hoverRow
                                                                  ? _c("i", {
                                                                      staticClass:
                                                                        "el-icon-remove operateDel",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.tableHandleRow(
                                                                              item.saasProductPossPrices,
                                                                              "del",
                                                                              scope.$index
                                                                            )
                                                                          },
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                                !scope.row
                                                                  .hoverRow
                                                                  ? _c("div", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            scope.$index +
                                                                              1
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                    _vm._l(
                                                      _vm.possNumTableField,
                                                      function (i, index_j) {
                                                        return _c(
                                                          "el-table-column",
                                                          {
                                                            key: index_j,
                                                            attrs: {
                                                              label: i.label,
                                                              align: "center",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var row =
                                                                      ref.row
                                                                    var $index =
                                                                      ref.$index
                                                                    return [
                                                                      i.prop !==
                                                                      "possPrice"
                                                                        ? _c(
                                                                            "el-form-item",
                                                                            {
                                                                              ref:
                                                                                "shop" +
                                                                                $index,
                                                                              refInFor: true,
                                                                              attrs:
                                                                                {
                                                                                  "label-width":
                                                                                    "0",
                                                                                  prop:
                                                                                    "payModelItems[" +
                                                                                    index_i +
                                                                                    "].saasProductPossPrices[" +
                                                                                    $index +
                                                                                    "][" +
                                                                                    i.prop +
                                                                                    "]",
                                                                                  rules:
                                                                                    item.isManagePoss
                                                                                      ? [
                                                                                          {
                                                                                            required: true,
                                                                                            message:
                                                                                              "请输入",
                                                                                            trigger:
                                                                                              "blur",
                                                                                          },
                                                                                        ]
                                                                                      : [],
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-input",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      oninput:
                                                                                        "value=value.toString().match(/^\\d+(?:\\.\\d{0,2})?/)",
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.discountPrices(
                                                                                          row,
                                                                                          "possOldPrice",
                                                                                          "possDiscount",
                                                                                          "possPrice"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        row[
                                                                                          i
                                                                                            .prop
                                                                                        ],
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            row,
                                                                                            i.prop,
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "row[i.prop]",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  row.possPrice
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "最大用户数",
                                      prop: "maxUsers",
                                      "label-width": "100px",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "mini",
                                        oninput:
                                          "value=value.replace(/[^0-9]/g,'')",
                                        placeholder: "请输入最大用户数",
                                      },
                                      model: {
                                        value: _vm.form.maxUsers,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "maxUsers", $$v)
                                        },
                                        expression: "form.maxUsers",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "最大门店数",
                                      prop: "maxShops",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "mini",
                                        oninput:
                                          "value=value.replace(/[^0-9]/g,'')",
                                        placeholder: "请输入最大门店数",
                                      },
                                      model: {
                                        value: _vm.form.maxShops,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "maxShops", $$v)
                                        },
                                        expression: "form.maxShops",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "最大站点数" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "w200",
                                      attrs: {
                                        size: "mini",
                                        oninput:
                                          "value=value.replace(/[^0-9]/g,'')",
                                        placeholder: "请输入最大站点数",
                                      },
                                      model: {
                                        value: _vm.form.maxPoss,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "maxPoss", $$v)
                                        },
                                        expression: "form.maxPoss",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }